<template>
  <div>
    <div v-if="!useCategoryStore.categoriesListLoading">
    <template v-for="category in categories" :key="category.id">
      <div class="flex justify-between items-center bg-white rounded px-4 my-2" :class="[depth === 0 ? 'hover:bg-gray-50' : '']">
        <div :class="[`py-3 pl-${depth * 4} text-sm font-medium text-gray-900`, depth === 0 ? 'cursor-pointer hover:underline' : 'hover:cursor-default']" @click="toggle(category.id)">
          {{ category.name }}
        </div>
        <div>
        <button v-if="depth === 0" @click.stop="openEditModal(category.id)" class="text-FLregistration hover:FLLight mr-2">
          <i class="fi fi-rr-pencil"></i>
        </button>
        <button v-if="depth === 0" @click.stop="confirmDelete(category.id)" class="text-red-500 hover:text-red-700">
          <i class="fi fi-rr-trash"></i>
        </button>
        </div>
      </div>
      <div v-if="openCategories[category.id]" class="pl-4">
        <category-list v-if="category.subcategories && category.subcategories.length"
                       :categories="category.subcategories"
                       :depth="depth + 1" />
      </div>
    </template>
    </div>
    <div v-else>

      <div v-for="i in 5" :key="i">
        <div class="pl-4 py-4">
          <SkeletonLoader :style="{width: '100%', display: 'block', height: '30px', borderRadius: '0.5rem'}" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { categoryStore } from "@/views/categories/stores/category.store";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";

export default {
  name: "CategoryList",
  components: {
  },
  props: {
    categories: Array,
    depth: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    let useCategoryStore = categoryStore();
    return {
      useCategoryStore,
    };
  },
  data() {
    return {
      openCategories: {},
    };
  },
  methods: {
    toggle(categoryId) {
      this.openCategories[categoryId] = !this.openCategories[categoryId];
    },
    confirmDelete(categoryId) {
      showDeleteConfirmation(() => this.deleteCategory(categoryId));
    },
    async deleteCategory(categoryId) {
      try {
        this.useCategoryStore.selectedCategoryId = categoryId;
        await this.useCategoryStore.deleteCategory();
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },

    openEditModal(mainCategoryId) {
      this.useCategoryStore.selectedCategoryId = mainCategoryId;
      this.useCategoryStore.openEditModal();
    },
  },
};
</script>

<template>
  <div class="w-full flex flex-col items-center p-4 sm:p-8">
    <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo" class="mb-4 sm:mb-8 ml-4 self-center">

    <div class="w-full max-w-4xl">
      <h1 class="text-xl mb-4 text-center">Welkom bij Fluitend Leren</h1>
      <h2 class="text-2xl mb-2 text-center">{{ $t('create demo account').capitalize() }}</h2>
      <p class="text-center mb-20">
        <button @click="goToLogin" class="underline text-black">Terug naar Inloggen</button>
      </p>

      <div class="w-full justify-center mb-8">
        <div class="text-center mb-4">
          {{ $t('step').capitalize() + ' ' + currentStep + ' ' + $t('of') + ' ' + totalSteps }}
        </div>
        <div class="w-full bg-gray-200 rounded-full h-1.5 relative">
          <div ref="progressBar" class="bg-FLregistration h-1.5 rounded-full absolute"></div>
        </div>
      </div>

      <swiper ref="mySwiper" :slides-per-view="1" :space-between="30" :auto-height="true" :observer="true" :observeParents="true" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide>
          <input-component class="w-full" v-model="useAuthStore.demoForm.firstname" input-type="text"
                           input-color="primary" placeholder="John" label="Voornaam"
                           @change="validateInput"/>
          <input-component class="w-full" v-model="useAuthStore.demoForm.lastname" input-type="text"
                           input-color="secondary" placeholder="Doe" label="Achternaam"
                           @change="validateInput"/>
        </swiper-slide>

        <swiper-slide v-if="useAuthStore.demoForm.lastname">
          <input-component class="w-full" v-model="useAuthStore.demoForm.email" input-type="email"
                           input-color="primary" placeholder="john.doe@example.com" label="Email"
                           @change="validateInput"/>
        </swiper-slide>

        <swiper-slide v-if="useAuthStore.demoForm.email">
          <select-component class="w-full" v-model="useAuthStore.demoForm.selectedDescribe"
                            :options="optionsDescribe" input-color="primary" :label="$t('select function').capitalize()"
                            @change="onDescribeChange"/>
          <input-component v-if="showInputDescribe" v-model="useAuthStore.demoForm.elseDescribe"
                           placeholder="Specify 'else'"
                           class="w-full" label="Please specify" @change="validateElseInput"/>
        </swiper-slide>

        <swiper-slide
            v-if="(useAuthStore.demoForm.selectedDescribe && useAuthStore.demoForm.selectedDescribe !== 'else') || useAuthStore.demoForm.elseDescribe">
          <input-component class="w-full" v-model="useAuthStore.demoForm.schoolName" input-type="text"
                           input-color="primary" placeholder="Name of school" :label="$t('name of school').capitalize()"
                           @change="validateInput"/>
          <label class="flex space-x-2">
            <input type="checkbox" v-model="useAuthStore.demoForm.acceptedPolicies"/>
            <span class="text-sm">
            {{ $t('i have read and accept the').capitalize() }}
            <button @click="showPrivacyPolicy = true" class="underline text-black">Privacybeleid</button>
            {{ $t('and') }}
            <button @click="showTermsOfService = true" class="underline text-black">Algemene Voorwaarden</button>
          </span>
          </label>
        </swiper-slide>
      </swiper>

      <div class="mt-4">

      </div>

      <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false"/>
      <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false"/>

      <div class="flex justify-center items-center mt-4 space-x-4">
        <button @click="goPrev" class="text-lg font-bold p-2"><icon-component icon="fi fi-br-arrow-left"></icon-component></button>
        <button-component :is-loading="useAuthStore.isLoading" type="submit"
                          class="shadow-lg px-10 py-2 w-full" buttonText="Probeer Demo" buttonColor="primary" :always-show-text="true"
                          :disabled="!isFormComplete" @click="submitDemoForm"/>
        <button @click="goNext" class="text-lg font-bold p-2"><icon-component icon="fi fi-br-arrow-right"></icon-component>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import SelectComponent from "@/components/forms/SelectComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import gsap from 'gsap';

SwiperCore.use([]);

export default {
  name: 'RegisterComponent',
  components: {
    IconComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    Swiper,
    SwiperSlide,
    PrivacyPolicy,
    TermsOfService
  },
  setup() {
    const useAuthStore = authStore();
    const mySwiper = ref(null);
    const progressBar = ref(null);

    const totalSteps = computed(() => 4);
    const currentStep = ref(1);

    return {useAuthStore, mySwiper, progressBar, totalSteps, currentStep};
  },
  data() {
    return {
      optionsDescribe: [
        {value: 'school leader', text: 'Schoolleider'},
        {value: 'adjunct director', text: 'Adjunct Directeur'},
        {value: 'intern supervisor', text: 'Intern Begeleider'},
        {value: 'teacher', text: 'Leerkracht'},
        {value: 'upper school board', text: 'Bovenschools Bestuur'},
        {value: 'working group with decision-making authority', text: 'Werkgroep met beslissingsbevoegdheid'},
        {value: 'else', text: 'Anders'}
      ],
      showPrivacyPolicy: false,
      showTermsOfService: false,
      elseDescribe: ''
    };
  },
  computed: {
    showInputDescribe() {
      return this.useAuthStore.demoForm.selectedDescribe === 'else';
    },
    isFormComplete() {
      const basicFieldsComplete = this.useAuthStore.demoForm.firstname &&
          this.useAuthStore.demoForm.lastname &&
          this.useAuthStore.demoForm.email &&
          (this.useAuthStore.demoForm.selectedDescribe || this.useAuthStore.demoForm.elseDescribe) &&
          this.useAuthStore.demoForm.schoolName;

      const elseFieldsComplete =
          (!this.showInputDescribe || this.useAuthStore.demoForm.elseDescribe.trim() !== '');

      return basicFieldsComplete && elseFieldsComplete && this.useAuthStore.demoForm.acceptedPolicies;
    },
  },
  methods: {
    goPrev() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slidePrev();
        this.updateProgressBar();
      }
    },
    goNext() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slideNext();
        this.updateProgressBar();
      }
    },
    validateInput() {
      if (!this.showInputDescribe) {
        this.goNext();
      }
    },
    validateElseInput() {
      if (this.useAuthStore.demoForm.elseDescribe.trim()) {
        this.goNext();
      }
    },
    onDescribeChange() {
      if (this.useAuthStore.demoForm.selectedDescribe !== 'else') {
        this.goNext();
      }
    },
    submitDemoForm() {
      if (this.isFormComplete) {
        this.useAuthStore.isLoading = true;
        this.useAuthStore.createDemoForm();
      }
    },
    updateProgressBar() {
      const step = this.mySwiper.value ? this.mySwiper.value.activeIndex + 1 : 1;
      const newWidth = (step / this.totalSteps) * 100;
      gsap.to(this.$refs.progressBar, {width: `${newWidth}%`, duration: 0.5});
      this.currentStep = step;
    },
    onSwiper(swiper) {
      this.mySwiper.value = swiper;
      this.updateProgressBar();
    },
    onSlideChange() {
      this.updateProgressBar();
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.transition-width {
  transition: width 0.5s ease-in-out;
}
</style>

<template>
  <div class="fixed inset-0 flex p-4 items-center justify-end bg-black bg-opacity-50 z-30 ">

    <div  class="bg-white md:w-4/5 mr-4 w-full rounded-lg relative text-center h-full my-10 overflow-y-auto">
      <button @click="useAppStore.showUpgradeFormModal = false" class="absolute top-8 right-8 text-2xl text-black">&times;</button>
      <img v-if="useAuthStore?.image"
           :src="useAuthStore?.image"
           alt="workspace logo" height="64" width="64" class="mx-auto mt-4 rounded-full border-solid border-1"/>
      <div class="calendly-inline-widget h-full" data-url="https://calendly.com/fluitend_leren/methode-presentatie-plannen">

      </div>
    </div>
  </div>
</template>

<script>
import { appStore } from "@/views/app.store";
import { authStore } from "@/views/auth/stores/auth.store";
import { userStore } from "@/views/users/stores/user.store";
export default {
  name: "UpgradeFormModal",
  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const useAppStore = appStore();

    return {
      useAppStore,
      useAuthStore,
      useUserStore
    };
  },
  mounted() {
    // Load the Calendly widget script dynamically
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }
};
</script>

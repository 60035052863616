<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white w-full overflow-x-auto md:h-[calc(100vh-140px)]">
  <div class="flex justify-between items-center mb-6">
      <div class="text-sm font-medium text-gray-600">
        {{ $t('amount of users').capitalize() }}: <span>{{ totalUsers }}</span>
      </div>
      <div>
        <button-component
            @click="openCreateModal()"
            v-if="useAuthStore.permissions.includes('users_create')"
            buttonText="Create user"
            buttonColor="secondary"
            prefix-icon="fi fi-rr-user-add"
        />
      </div>
    </div>

    <table class="min-w-full bg-white border-collapse">
      <thead>
      <tr>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('workspace name').capitalize() }}
        </th>
      </tr>
      </thead>
      <tbody>
      <!-- Workspaces -->
      <tr v-for="workspace in data.workspaces" :key="workspace.uuid" class="bg-gray-50 hover:bg-gray-100">
        <td colspan="5" class="px-5 py-4 border-b border-gray-200">
          <div @click="toggleDropdown(workspace.uuid)" class="cursor-pointer">
            <strong>{{ workspace.name }}</strong>
            <span class="text-gray-500 text-xs"> ({{ $t('users').capitalize() }}: {{ workspace.users.length }} )</span>
            <icon-component
                :icon="isDropdownOpen(workspace.uuid) ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                class="ml-2 text-gray-500"
            />
          </div>

          <!-- Nested Dropdown for Workspace Users -->
          <div v-if="isDropdownOpen(workspace.uuid)" class="mt-3">
            <table class="w-full mt-3">
              <thead>
              <tr>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('first name').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('last name').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('email').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('role').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('actions').capitalize() }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in workspace.users" :key="user.uuid" class="hover:bg-gray-50 transition duration-200">
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.first_name }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.last_name }}</td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.email }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">
                  {{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                </td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm space-x-2">
                  <!-- Action buttons -->
                  <button v-if="useAuthStore?.user?.uuid !== user?.uuid &&
                                                      (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher')"
                          @click="confirmDeleteUser(user?.uuid)"
                          class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150">
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                          @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                          class="inline-flex items-center text-FLregistration hover:text-FLregistrationlight transition duration-150">
                    <icon-component icon="fi fi-sr-up"></icon-component>
                  </button>

                  <button v-if="user?.roles && user?.roles[0]?.name === 'school_director' &&
                                useAuthStore?.user?.roles?.[0]?.name === 'reseller_admin' &&
                                useAuthStore?.user?.uuid !== user?.uuid"
                          @click="confirmUpdateUser(user?.uuid)"
                          class="inline-flex items-center text-FLregistration hover:text-FLregistrationlight transition duration-150">
                    <icon-component icon="fi fi-sr-down"></icon-component>
                  </button>

                  <button v-if="useAuthStore?.user?.workspace?.[0]?.books?.length > 0 &&
                                    (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher') &&
                                    user?.workspace && user.workspace?.length > 0 &&
                                    useAuthStore?.user?.workspace?.[0]?.uuid !== user.workspace?.[0]?.uuid"
                          @click="openLinkBooksModal(user.workspace?.[0]?.uuid, user.workspace?.[0]?.books)"
                          class="inline-flex items-center text-FLregistrationlight hover:text-FLregistration mx-2 transition duration-150">
                    <i class="fi fi-sr-book-alt"></i>
                  </button>

                  <button v-if="useAuthStore?.user?.workspace?.[0]?.programs?.length > 0 &&
                                    (useAuthStore?.user?.roles?.[0]?.name !== 'reseller_admin' || user?.roles?.[0]?.name !== 'teacher') &&
                                    user?.workspace && user.workspace?.length > 0 &&
                                    useAuthStore?.user?.workspace?.[0]?.uuid !== user.workspace?.[0]?.uuid"
                          @click="openLinkProgramsModal(user.workspace?.[0]?.uuid, user.workspace?.[0]?.programs)"
                          class="inline-flex items-center text-FLregistrationlight hover:text-FLregistration mx-2 transition duration-150">
                    <i class="fi fi-sr-calendar-lines"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>

      <!-- Users without Workspace -->
      <tr v-if="data.usersWithoutWorkspace.length > 0" class="bg-gray-50 hover:bg-gray-100">
        <td colspan="5" class="px-5 py-4 border-b border-gray-200">
          <div @click="toggleDropdown('no-workspace')" class="cursor-pointer">
            <strong>{{ $t('users without workspace').capitalize() }}</strong>
            <span class="text-gray-500 text-xs"> ({{ $t('users').capitalize() }}: {{ data.usersWithoutWorkspace.length }} )</span>
            <icon-component
                :icon="isDropdownOpen('no-workspace') ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                class="ml-2 text-gray-500"
            />
          </div>

          <!-- Nested Dropdown for Users Without Workspace -->
          <div v-if="isDropdownOpen('no-workspace')" class="mt-3">
            <table class="w-full mt-3">
              <thead>
              <tr>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('first name').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('last name').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('email').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('role').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('actions').capitalize() }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in data.usersWithoutWorkspace" :key="user.uuid" class="hover:bg-gray-50 transition duration-200">
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.first_name }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.last_name }}</td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.email }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">
                  {{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                </td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm space-x-2">
                  <!-- Action buttons -->
                  <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                          @click="confirmDeleteUser(user?.uuid)"
                          class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150">
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                          @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                          class="inline-flex items-center text-FLregistration hover:text-FLregistrationlight transition duration-150">
                    <icon-component icon="fi fi-sr-up"></icon-component>
                  </button>

                  <button v-if="user?.roles && user?.roles[0]?.name === 'school_director'"
                          @click="confirmUpdateUser(user?.uuid)"
                          class="inline-flex items-center text-FLregistration hover:text-FLregistrationlight transition duration-150">
                    <icon-component icon="fi fi-sr-down"></icon-component>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>

      <!-- Demo Users -->
      <tr v-if="data.demoUsers.length > 0" class="bg-gray-50 hover:bg-gray-100">
        <td colspan="5" class="px-5 py-4 border-b border-gray-200">
          <div @click="toggleDropdown('demo-users')" class="cursor-pointer">
            <strong>{{ $t('demo users').capitalize() }}</strong>
            <span class="text-gray-500 text-xs"> ({{ $t('users').capitalize() }}: {{ data.demoUsers.length }} )</span>
            <icon-component
                :icon="isDropdownOpen('demo-users') ? 'fi fi-sr-angle-up' : 'fi fi-sr-angle-down'"
                class="ml-2 text-gray-500"
            />
          </div>

          <!-- Nested Dropdown for Demo Users -->
          <div v-if="isDropdownOpen('demo-users')" class="mt-3">
            <table class="w-full mt-3">
              <thead>
              <tr>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('first name').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('last name').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('email').capitalize() }}
                </th>
                <th class="w-1/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('role').capitalize() }}
                </th>
                <th class="w-2/6 px-5 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {{ $t('actions').capitalize() }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in data.demoUsers" :key="user.uuid" class="hover:bg-gray-50 transition duration-200">
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.first_name }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.last_name }}</td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm">{{ user.email }}</td>
                <td class="w-1/6 px-5 py-2 border-b border-gray-200 text-sm">
                  {{ user.roles && user.roles.length > 0 ? $t(user.roles[0].name.toLowerCase()).capitalize() : '' }}
                </td>
                <td class="w-2/6 px-5 py-2 border-b border-gray-200 text-sm space-x-2">
                  <!-- Action buttons -->
                  <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                          @click="confirmDeleteUser(user?.uuid)"
                          class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150">
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <button v-if="user?.roles && user?.roles[0]?.name === 'demo_user'"
                          @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
                          class="inline-flex items-center text-FLregistration hover:text-FLregistrationlight transition duration-150">
                    <icon-component icon="fi fi-sr-up"></icon-component>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>



<script>
import { reactive, computed } from 'vue';
import { userStore } from "@/views/users/stores/user.store.js";
import { authStore } from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import { showCustomAlert, showDeleteConfirmation } from "@/helpers/sweetAlert";

export default {
  name: 'OverviewUserComponent',
  components: { ButtonComponent, IconComponent },

  setup() {
    const useUserStore = userStore();
    const useAuthStore = authStore();
    const openDropdowns = reactive({});

    const toggleDropdown = (id) => {
      openDropdowns[id] = !openDropdowns[id];
    };

    const isDropdownOpen = (id) => {
      return openDropdowns[id];
    };

    // Total users count for display
    const totalUsers = computed(() => {
      return (
          useUserStore.categorizedUsers.workspaces.reduce((count, ws) => count + ws.users.length, 0) +
          useUserStore.categorizedUsers.usersWithoutWorkspace.length +
          useUserStore.categorizedUsers.demoUsers.length
      );
    });

    return {
      useAuthStore,
      useUserStore,
      data: useUserStore.categorizedUsers,
      toggleDropdown,
      isDropdownOpen,
      totalUsers,
    };
  },
  methods: {
    openCreateModal() {
      this.useUserStore.showEditUserModal = true;
      this.useUserStore.editMode = false;
    },
    openLinkBooksModal(workspaceUuid, selectedUserBooks) {
      this.useUserStore.selectedWorkspaceBooks = selectedUserBooks;
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid;
      this.useUserStore.showEditUserBooksModal = true;
      this.useUserStore.editUserBooksMode = false;
    },
    openLinkProgramsModal(workspaceUuid, selectedUserPrograms) {
      this.useUserStore.selectedWorkspacePrograms = selectedUserPrograms;
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid;
      this.useUserStore.showEditUserProgramsModal = true;
      this.useUserStore.editUseProgramsMode = false;
    },
    async confirmDeleteUser(userUuid) {
      showDeleteConfirmation(() => this.deleteUser(userUuid));
    },
    async deleteUser(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.deleteUser();
    },
    async confirmUpdateUser(userUuid, userRequested) {
      showCustomAlert(() => this.updateUserRole(userUuid), 'update user', 'are you sure you want to update this user?', {}, {}, 'success', 'successfully updated', {}, {}, userRequested ? 'deny request' : null, userRequested ? () => this.denyUserRequest(userUuid) : null);
    },
    async updateUserRole(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.updateUserRole();
    },
    async denyUserRequest(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.denyUserRequest();
    },
  },
};
</script>

import axios from "axios";
import {getCookieValue} from "@/auth";
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {showErrorAlert} from "@/helpers/sweetAlert";
import {userStore} from "@/views/users/stores/user.store";

axios.interceptors.response.use((response) => {

    return response;
}, function (error) {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const useUserStore = userStore();


    if (!error.response) {
        return Promise.reject(error);
    }

    if (error.response.status === 401) {
        useAuthStore.logout(401);

    }
    if (error.response.status === 403) {
        useAppStore.showUpgradeFormModal = false

        showErrorAlert('something went wrong', error.response.data.message.lowercase())
    }

    if (error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorMessage = "";
        Object.keys(errors).forEach((key) => {
            errors[key].forEach((msg) => {
                errorMessage += msg
            });
        });
        console.log(errorMessage)
        showErrorAlert('error', errorMessage.lowercase());
        useAuthStore.isLoading = false;
        useUserStore.isLoading = false;
        useUserStore.disabled = false;
    }

    return Promise.reject(error);
});


export async function getData(url, params = null, config = {}, checkAuth = false) {
    let response;
    let paramKeys = [];

    if (checkAuth) {
        const token = getCookieValue('accessToken');
        if (!token) {
            throw new Error("No access token found");
        }
        config.headers = {Authorization: `Bearer ${token}`}
    }

    if (params) {
        Object.keys(params).map(key => {
            if (params[key] !== undefined && params[key] !== null) {
                paramKeys.push(key + '=' + params[key])
            }
            return paramKeys;
        });

        const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
        url = url + queryString;
    }
    response = axios.get(url, config);

    return response;
}

export async function postData(url, data, params = null, config = {}, checkAuth = false) {
    const useAuthStore = authStore();


    let paramKeys = [];
    config.headers = config.headers || {};

    if (checkAuth) {
        const token = getCookieValue('accessToken');
        if (!token) {
            useAuthStore.logout();
            throw new Error("No access token found");

        }
        config.headers.Authorization = `Bearer ${token}`;
    }

    if (params) {
        Object.keys(params).map(key => {
            if (params[key]) {
                paramKeys.push(key + '=' + params[key])
            }
            return paramKeys;
        });

        const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
        url = url + (queryString ? '?' + queryString : '');
    }

    return await axios.post(url, data, config);
}

export async function patchData(url, data, params = null, config = {}, checkAuth = false) {

    const useAuthStore = authStore();

    let paramKeys = [];

    config.headers = config.headers || {};

    if (checkAuth) {
        const token = getCookieValue('accessToken');

        if (!token) {
            useAuthStore.logout();
            throw new Error("No access token found");

        }
        config.headers.Authorization = `Bearer ${token}`;

    }

    if (params) {
        Object.keys(params).map(key => {
            if (params[key]) {
                paramKeys.push(key + '=' + params[key])
            }
            return paramKeys;
        });

        const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
        url = url + (queryString ? '?' + queryString : '');
    }

    return await axios.patch(url, data, config);
}

export async function deleteData(url, data = {}, params = null, config = {}, checkAuth = false) {
    let paramKeys = [];

    if (checkAuth) {
        const token = getCookieValue('accessToken');
        if (!token) {
            throw new Error("No access token found");
        }
        config.headers = {Authorization: `Bearer ${token}`}

    }

    if (params) {
        Object.keys(params).map(key => {
            if (params[key]) {
                paramKeys.push(key + '=' + params[key])
            }
            return paramKeys;
        });

        const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
        url = url + (queryString ? '?' + queryString : '');
    }
    config.data = data

    return await axios.delete(url, config);
}
<template>
  <router-view>
    <div class="grid grid-cols-9 md:gap-4 p-6">
      <div v-if="!useLibraryStore.showEditBookModal" class="col-span-9 lg:hidden">
        <button @click="toggleFilter" class="p-2 border rounded-lg w-full text-black text-md shadow-lg">
          <icon-component icon="fi fi-rr-settings-sliders" class="mr-2"/>
          {{ $t('filter on').capitalize() }}
        </button>
      </div>
      <filter-component v-if="useLibraryStore.filters" :is-disabled="useLibraryStore.isLoadingBooks" :filters="useLibraryStore.filters" class="hidden lg:block col-span-2"/>
      <overview-book-component class="flex flex-col md:col-span-7 col-span-9 mt-6 md:mt-0" :libraries="libraries"/>
      <edit-book-view v-if="useLibraryStore.showEditBookModal"/>
    </div>
    <div v-if="showMobileFilter" class="bg-gray-800 bg-opacity-50 z-50 lg:hidden">
      <div class="fixed inset-y-0 left-0  w-4/5 max-w-sm bg-white p-4 animate-slide-in md:animate-none">
        <button-component prefix-icon="fi fi-rs-cross-small" buttonColor="secondary" @click="toggleFilter" class="ml-auto text-sm">
        </button-component>
        <filter-component :filters="useLibraryStore.filters"/>
      </div>
    </div>
  </router-view>
</template>

<script>
import FilterComponent from "@/views/library/components/FilterComponent.vue";
import EditBookView from "@/views/library/EditBookView.vue";
import OverviewBookComponent from "@/views/library/components/OverviewBookComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import { libraryStore } from "@/views/library/stores/library.store.js";
import { computed, ref } from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: 'ShowAllLibraryView',
  components: {ButtonComponent, OverviewBookComponent, FilterComponent, EditBookView, IconComponent },

  setup() {
    const useLibraryStore = libraryStore();
    const showMobileFilter = ref(false);

    const libraries = computed(() => {
      return Array.isArray(useLibraryStore.books) ? useLibraryStore.books.map(book => ({
        uuid: book.uuid,
        bookName: book.name,
        categories: book.categories,
        description: book.description,
        image_name: book.image_name,
        is_demo: book.is_demo,
        image: book.image,
        updated_at: book.updated_at,
        showImage: true,
        views: book.views,
      })) : [];
    });

    const toggleFilter = () => {
      showMobileFilter.value = !showMobileFilter.value;
    };


    return {
      libraries,
      useLibraryStore,
      showMobileFilter,
      toggleFilter,
    };
  },
  mounted() {
    this.getBooks();
  },
  methods: {
    async getBooks() {
      await this.useLibraryStore.fetchAllCategories();
      let setFilters = [];
      for (let i = 0; i < this.useLibraryStore.categories.length; i++) {
        let category = {
          name: this.useLibraryStore.categories[i].name,
          value: this.useLibraryStore.categories[i].id,
          checked: false,
          filterProperties: {},
          filterType: 'parent',
          filter: []
        };
        if (this.useLibraryStore.categories[i].subcategories.length) {
          for (let j = 0; j < this.useLibraryStore.categories[i].subcategories.length; j++) {
            category.filter.push({
              filterType: 'child',
              checked: false,
              name: this.useLibraryStore.categories[i].subcategories[j].name,
              value: this.useLibraryStore.categories[i].subcategories[j].id,
            });
          }
        }
        setFilters.push(category);
      }
      this.useLibraryStore.filters = setFilters;

      this.useLibraryStore.queryFilter = 'per_page=' + this.useLibraryStore.pagination.perPage;

      await this.useLibraryStore.fetchAllBooks(1, null);
    }
  }
}
</script>

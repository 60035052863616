<template>
  <div class="p-8">
    <h1 class="text-xl pb-4">{{$t('bug reports').capitalize()}}</h1>
    <div class="overflow-x-auto">
      <!-- Table Wrapper -->
      <table class="min-w-full border border-gray-200 rounded-lg">
        <thead>
        <tr class="bg-gray-100 text-left">
          <th class="py-2 px-4 text-sm font-medium text-gray-700 border-b">Name</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-700 border-b">Email</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-700 border-b">Description</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-700 border-b">Date</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bug in useAnalyticsStore.bugs" :key="bug.id" class="hover:bg-gray-50">
          <td class="py-2 px-4 text-sm text-gray-800 border-b">
            {{ bug.user.first_name }} {{ bug.user.last_name }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-800 border-b">
            {{ bug.user.email }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-600 border-b">
            {{ bug.description }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-500 border-b">
            {{ bug.created_at.humanize() }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import { analyticsStore } from "@/views/analytics/stores/analytics.store.js";

export default {
  name: 'BugListComponent',

  setup() {
    const useAnalyticsStore = analyticsStore();
    return {
      useAnalyticsStore
    }
  },

  mounted() {
    this.getBugReports();
  },

  methods: {
    async getBugReports() {
      await this.useAnalyticsStore.getAllBugReports();
    }
  }
}
</script>

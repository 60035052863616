<template>
  <div v-if="useAgendaStore.showEditProgramModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 index-50 overflow-y-auto h-full w-full" @click.self="closeModal">

    <div class="relative top-20 mx-auto p-5 border w-full  md:w-1/3 shadow-lg rounded-md bg-white">
      <!-- Close Icon -->
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="!useAgendaStore.editMode">{{$t('create a new program').capitalize()}}</h3>
      <h3 class="text-lg leading-6 font-medium text-gray-900" v-else>{{$t('update program').capitalize()}}</h3>
      <hr>

      <form @submit.prevent="submitProgram()" class="pt-2">
        <input-component label="naam"  v-model="useAgendaStore.form.name" input-type="text" placeholder="" inputColor="primary" :required="true"/>
        <textarea-component label="domain" v-model="useAgendaStore.form.description" placeholder="" inputColor="secondary" :required="true"/>
        <h1>{{$t('choose your lessons').capitalize()}}</h1>
        <hr>
        <div class="max-h-48 overflow-y-auto">
        <checkbox-component v-for="book in books"
                            :checked="useAgendaStore?.form.book_uuids?.includes(book.uuid)"
                            @update:checked="checkBook(book.uuid, $event)"
                            :label="book.name"
                            :fieldId="book.uuid"
                            :key="book"
                            v-model="useAgendaStore.form.book_uuids"/>
        </div>
        <button-component :buttonText="useAgendaStore.editMode ? 'update' : 'create'" buttonColor="secondary" prefix-icon="fi fi-rr-paper-plane-top"  class="mt-4 sm:w-full md:w-fit md:ml-auto" :is-loading="useAgendaStore.loadEditProgram"/>
      </form>
    </div>
  </div>
</template>


<script>
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {libraryStore} from "@/views/library/stores/library.store.js";
import InputComponent from "@/components/forms/InputComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue"


export default {
  name: 'EditAgendaViewComponent',
  components: {ButtonComponent, InputComponent, TextareaComponent, CheckboxComponent},
  setup() {
    const useAgendaStore = agendaStore();
    const useLibraryStore = libraryStore();
    return {
      useAgendaStore,
      useLibraryStore
    }
  },
  data() {
    return {
      books: []
    }
  },
  mounted() {

    this.getBooks()
  },
  methods: {
    async checkBook(bookUuid, checked){
      let storeSelectedBooks = this.useAgendaStore.form.book_uuids
      if(checked){
        storeSelectedBooks.push(bookUuid)
      } else {
        storeSelectedBooks.splice(storeSelectedBooks.indexOf(bookUuid), 1)
      }
    },
    async submitProgram() {
      this.useAgendaStore.loadEditProgram = true;
      if(this.useAgendaStore.editMode) {
        await this.useAgendaStore.updateProgram();
      } else {
        await this.useAgendaStore.createProgram();

      }
    },

    async getBooks() {

      await this.useLibraryStore.fetchAllBooks(null, 'program');
      this.books = this.useAgendaStore.books;
    },
    closeModal() {
      this.useAgendaStore.showEditProgramModal = false
    }
  }
}
</script>

<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="$emit('close')">
    <div class="bg-white p-6 rounded-lg max-w-2xl w-full h-full overflow-y-auto relative">
      <h1 class="text-2xl font-bold mb-4">Algemene Voorwaarden voor Edulecta</h1>
      <p class="mb-4"><strong>1. Verplichtingen van Gebruikers en Verboden Handelingen</strong><br>
        1.1 Ongeautoriseerd Delen: Het is gebruikers strikt verboden om lesmateriaal dat via het Edulecta-platform wordt aangeboden, te delen of te verspreiden aan onbevoegde partijen. Overtreding van deze bepaling kan leiden tot juridische stappen volgens de Auteurswet (Artikel 1, 15b).
        <br>
        1.2 Accountverantwoordelijkheid: De klant is verantwoordelijk voor het beheer en gebruik van de accounts die zij voor hun gebruikers aanmaken, inclusief het waarborgen dat gebruikers zich aan deze Voorwaarden houden. Dit valt onder het Burgerlijk Wetboek (Artikel 6:162 BW).
        <br>
        1.3 Misbruik van de Software: Elke poging om de functionaliteit van het platform te verstoren door middel van bugs of ongeautoriseerde toegang is verboden en kan juridische consequenties hebben onder Artikel 6:162 BW.
      </p>
      <p class="mb-4"><strong>2. Intellectuele Eigendomsrechten</strong><br>
        Alle lesmaterialen blijven het intellectueel eigendom van de klanten, conform de Auteurswet (Artikel 1). Edulecta heeft een niet-exclusieve licentie om de materialen te hosten voor dienstverlening volgens Artikel 7:13 BW.
      </p>
      <p class="mb-4"><strong>3. Aansprakelijkheid en Vrijwaring</strong><br>
        Xendd is niet aansprakelijk voor ongeautoriseerd gebruik van lesmateriaal door derden. Onder Artikel 6:196c BW is Xendd vrijgesteld van aansprakelijkheid voor door derden opgeslagen informatie, tenzij er sprake is van bewuste overtredingen.
      </p>
      <p class="mb-4"><strong>4. Beheer van Inhoud</strong><br>
        De klant is verantwoordelijk voor het beheer van lesmateriaal en toegangsrechten van gebruikers. Alleen bevoegde gebruikers mogen toegang krijgen tot bepaalde materialen, volgens Artikel 6:217 BW.
      </p>
      <p class="mb-4"><strong>5. Beëindiging en Gevolgen van Overtredingen</strong><br>
        Xendd behoudt zich het recht voor om bij overtredingen van deze Voorwaarden accounts te beëindigen en juridische stappen te ondernemen, in overeenstemming met Artikel 6:265 BW.
      </p>
      <p class="mb-4"><strong>6. Privacy en Gegevensbescherming</strong><br>
        Xendd verwerkt persoonlijke gegevens in overeenstemming met de AVG. Gegevens worden opgeslagen binnen de EU, conform Artikel 5 en 28 AVG.
      </p>
      <p class="mb-4"><strong>7. Toepasselijk Recht en Bevoegde Rechtbank</strong><br>
        Op deze Voorwaarden is uitsluitend Nederlands recht van toepassing, in lijn met Artikel 6:247 BW. Geschillen worden voorgelegd aan de bevoegde rechter in [plaats].
      </p>
      <p class="mb-4"><strong>8. Gebruikerslicentie en Toegang</strong><br>
        Edulecta verleent een beperkte, niet-exclusieve licentie voor het gebruik van de software voor educatieve doeleinden, zoals vastgelegd in Artikel 7:13 BW. Toegang wordt beveiligd door wachtwoordbescherming, in overeenstemming met de AVG.
      </p>
      <p class="mb-4"><strong>9. Gegevensopslag en Back-ups</strong><br>
        Lesmateriaal wordt opgeslagen op beveiligde servers binnen de EU volgens de AVG (Artikel 5 en 32). Xendd maakt regelmatig back-ups, maar is niet aansprakelijk voor gegevensverlies door overmacht, volgens Artikel 6:75 BW.
      </p>
      <p class="mb-4"><strong>10. Kosten, Betaling en Abonnementen</strong><br>
        Het gebruik van Edulecta is op abonnementsbasis. Niet-betaling kan leiden tot opschorting van de toegang, volgens Artikel 7:27 BW.
      </p>
      <p class="mb-4"><strong>11. Meldplicht bij Misbruik of Beveiligingsproblemen</strong><br>
        Klanten moeten misbruik of beveiligingsproblemen onmiddellijk melden, zoals vereist door de Wet Computercriminaliteit (Artikel 138a t/m 138c Wetboek van Strafrecht).
      </p>
      <p class="mb-4"><strong>12. Wijzigingen in de Algemene Voorwaarden</strong><br>
        Xendd behoudt zich het recht voor om deze Voorwaarden te wijzigen. Wijzigingen worden 30 dagen vooraf aangekondigd, in overeenstemming met Artikel 6:234 BW.
      </p>
      <p class="mb-4"><strong>13. Vertrouwelijkheid en Geheimhouding</strong><br>
        Informatie gemarkeerd als vertrouwelijk mag niet openbaar worden gemaakt zonder toestemming van Xendd, zoals vastgelegd in Artikel 7:413 BW.
      </p>
      <p class="mb-4"><strong>14. Beperking van Functionaliteit en Onderhoud</strong><br>
        Xendd behoudt zich het recht voor om onderhoud uit te voeren, wat kan leiden tot tijdelijke beperkingen, in overeenstemming met Artikel 6:75 BW.
      </p>
      <p class="mb-4"><strong>15. Gebruik van Derden</strong><br>
        Edulecta kan gebruikmaken van diensten van derden. Door gebruik te maken van Edulecta stemt u in met de voorwaarden van deze derden, in lijn met Artikel 6:248 BW.
      </p>
      <button @click="$emit('close')" class="absolute top-2 right-2 text-gray-600">&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
};
</script>

<style scoped>
@media (max-width: 768px) {
  .bg-white {
    max-width: 90%;
    height: auto;
    max-height: 80%;
    padding: 2%;
  }
}

@media (min-width: 769px) {
  .bg-white {
    max-width: 40rem;
    height: 100vh;
    padding: 2%;
  }
}
</style>
<template>
  <div class="grid-cols-1 grid gap-4 p-6 h-[calc(100vh-140px)]">
    <form @submit.prevent="updateWorkspace()" class="border rounded-lg shadow-lg p-8 bg-white">
      <h3 class="text-xl leading-6 font-medium text-gray-900">
        {{ $t('workspace settings').capitalize() }}
      </h3>
      <hr class="mb-3">
      <input type="hidden" v-model="useAuthStore.workspace.uuid" required/>
      <input-component type="text" v-model="localWorkspaceName" placeholder="name of your institute" inputColor="primary"
                       label="profile name" class="w-full" required/>
      <textarea-component placeholder="" v-model="useAuthStore.workspace.description"
                          inputColor="secondary"
                          class="w-full col-span-2 mb-4" label="description"/>
      <div class="w-full mb-8">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="thumbnail">
          {{ useUserStore.workspaceForm.image_name || $t('change profile logo').capitalize() }}
        </label>
        <input id="thumbnail" type="file" accept="image/jpeg" @change="handleImageUpload($event)" class="hidden"/>
        <label for="thumbnail" class="cursor-pointer py-2 px-4 bg-FLregistration text-white rounded-md shadow-sm hover:bg-FLregistrationlight">
          {{$t('change profile logo').capitalize()}}
        </label>
      </div>
      <h3 class="text-xl leading-6 font-medium text-gray-900">
        {{ $t('profile settings').capitalize() }}
      </h3>
      <hr class="mb-3">

      <input type="hidden" v-model="useAuthStore.workspace.uuid" required/>
      <div class="grid grid-cols-2 gap-4">
        <input-component type="text" v-model="useUserStore.form.first_name" placeholder="first name"
                         inputColor="primary" label="first name" class="w-full" required/>
        <input-component type="text" v-model="useUserStore.form.last_name" placeholder="last name" inputColor="primary"
                         label="last name" class="w-full" required/>
      </div>
      <div class="flex space-x-4 mt-4">
        <button-component type="submit" class="text-sm" buttonText="update" prefix-icon="fi fi-rr-paper-plane-top"
                          :is-loading="useUserStore.isLoadingUserSettings" buttonColor="secondary"/>
        <button-component type="button" @click="confirmDeleteWorkspace" prefix-icon="fi fi-rr-trash"
                          class="text-sm bg-red-600" buttonText="delete workspace"/>
      </div>
    </form>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";
import {authStore} from "@/views/auth/stores/auth.store";
import {userStore} from "@/views/users/stores/user.store";
import {useRouter} from 'vue-router';

export default {
  name: 'ShowSettings',
  components: {TextareaComponent, InputComponent, ButtonComponent},
  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const router = useRouter();
    const localWorkspaceName = ref('');
    const sweetAlert = ref(null);

    onMounted(() => {
      localWorkspaceName.value = useAuthStore.workspace.name;
    });

    const notification = ref({
      message: '',
      type: 'success',
    });

    async function updateWorkspace() {
      try {
        useAuthStore.workspace.name = localWorkspaceName.value;
        useUserStore.isLoadingUserSettings = true;
        await useUserStore.updateWorkspace();
      } catch (error) {
        console.error("Error updating workspace:", error);
      } finally {
        useUserStore.isLoadingUserSettings = false;
      }
    }

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        useUserStore.workspaceForm.image_name = file.name;
        useUserStore.workspaceForm.image = file;
      } else {
        useUserStore.workspaceForm.image_name = '';
        useUserStore.workspaceForm.image = null;
      }
    };

    const confirmDeleteWorkspace = () => {
      showDeleteConfirmation(() => deleteWorkspace(useAuthStore.workspace.uuid));
    };

    const deleteWorkspace = async (uuid) => {
      try {
        await useUserStore.deleteWorkspace(uuid);
      } catch (error) {
        console.error("Error deleting workspace:", error);
      }
    };

    return {
      useAuthStore,
      useUserStore,
      router,
      notification,
      localWorkspaceName,
      updateWorkspace,
      handleImageUpload,
      confirmDeleteWorkspace,
      sweetAlert,
    };
  },

  mounted() {
    this.getUser();
  },

  methods: {
    async getUser() {
      this.useUserStore.form.first_name = this.useAuthStore.user.first_name;
      this.useUserStore.form.last_name = this.useAuthStore.user.last_name;
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-4 grid-rows-5 gap-4 md:h-[calc(100vh-140px)]">
    <!-- Introduction Section for Demo Users -->
    <div v-if="isDemoUser()"
         class="md:col-span-3 col-span-4 row-span-2 border rounded-lg shadow-lg p-8 bg-white flex flex-col justify-between">
      <div>
        <h1 class="md:text-2xl text-lg font-bold mb-8 rounded-br-lg md:w-60 w-40 rounded-tl-lg">
          {{ $t('welcome').capitalize() }}!</h1>
        <p>
          {{
            $t('welcome at the online library of Fluitend Leren. Here, you will find dynamic learning for lessons between grade 1 up to 8.').capitalize()
          }}
        </p>
        <p class="pt-4">
          {{
            $t('would you like to structurally incorporate Dynamic Learning within your primary school\'s current language and maths teaching,').capitalize()
          }}
          <span class="underline">{{ $t('without') }}</span> {{ $t('extra workload') }}?
        </p>
      </div>
      <div v-if="useAuthStore.role && useAuthStore?.role?.name === 'demo_user'" class="rounded-tr-xl rounded-br-xl">
        <button-component
            @click="useAuthStore?.user?.request_upgrade ? useAppStore.showUpgradeFormModal = false : useAppStore.showUpgradeFormModal = true"
            :always-show-text="true" class="hover:scale-105 md:text-lg !mb-0 text-xs !p-6 text-white"
            button-color="secondary" :button-text="useAuthStore?.user?.request_upgrade ? 'requested!' : 'get access'"
            :disabled="!!useAuthStore?.user?.request_upgrade"/>
      </div>
    </div>
    <div v-else
         class="md:col-span-4 col-span-4 row-span-3 border rounded-lg shadow-lg p-8 bg-white flex flex-col justify-between"
    >
      <div class="flex flex-row justify-between">
        <h1 class="md:text-2xl text-lg font-bold mb-8 rounded-br-lg md:w-60 w-40 rounded-tl-lg">
          {{ $t('library').capitalize() }}</h1>
        <RouterLink to="/library" class="underline">{{ $t('view all lessons').capitalize() }}</RouterLink>
      </div>
      <div
          class="grid grid-flow-col auto-cols-max md:grid-cols-5 gap-4 overflow-x-auto whitespace-nowrap md:overflow-x-scroll md:whitespace-normal flex-1"
          v-if="libraries.length">
        <card v-for="library in libraries" :key="library.uuid"
              :class="['bg-FLSecondary h-fit text-white rounded transform transition duration-200 hover:scale-105 hover:bg-FLPrimary hover:text-white cursor-pointer']"
              @edit="updateBook(library.uuid)"
              @delete="deleteBook(library.uuid)"
              @show="openBook($event, library)"
              updatePermission="books_update"
              deletePermission="books_delete"
              showPermission="books_view"
              :allow-edits="true"
              :is-locked="isLocked(library.is_demo)"
              :is-demo="!!library.is_demo"
              :edit-detail-button="false"
              :delete-detail-button="false"
              :title="library.bookName"
              :categories="library.categories"
              :show-image="library.showImage"
              :image="library.image"
              :image-height="'[32rem]'"
              :views="library.views"
              title-size="md"/>
      </div>
      <div v-else class="flex-1">
        <h1 class="text-">{{ $t("there are no lessons").capitalize() }}</h1>
      </div>
    </div>
    <div  v-if="useAuthStore.role && useAuthStore?.role?.name === 'demo_user'"
          class="md:col-span-1 col-span-4 md:row-span-2 row-span-3 border rounded-lg shadow-lg bg-white flex flex-col">
      <vue-plyr class="w-full h-full">
        <div data-plyr-provider="youtube" data-plyr-embed-id="UhSSunSR3qY"></div>
      </vue-plyr>
    </div>
    <div v-if="isDemoUser()"
         class="md:col-span-3 col-span-4 row-span-3 border rounded-lg shadow-lg p-8 bg-white flex flex-col">
      <div class="flex flex-row justify-between">
        <h1 class="md:text-2xl mb-8 text-lg font-bold rounded-br-lg w-40 md:w-60 rounded-tl-lg">
          {{ $t('library').capitalize() }}</h1>
        <RouterLink to="/library" class="underline">{{ $t('view all lessons').capitalize() }}</RouterLink>
      </div>
      <div
          class="grid grid-flow-col auto-cols-max md:grid-cols-4 gap-4 overflow-x-auto whitespace-nowrap md:overflow-x-visible md:whitespace-normal flex-1"
          v-if="libraries.length">
        <card v-for="library in libraries" :key="library.uuid"
              :class="['bg-FLSecondary h-fit text-white rounded transform transition duration-200 hover:scale-105 hover:shadow-2xl hover:bg-FLPrimary hover:text-white cursor-pointer drop-shadow-lg']"
              @edit="updateBook(library.uuid)"
              @delete="deleteBook(library.uuid)"
              @show="openBook($event, library)"
              updatePermission="books_update"
              deletePermission="books_delete"
              showPermission="books_view"
              :allow-edits="true"
              :is-locked="isLocked(library.is_demo)"
              :is-demo="!!library.is_demo"
              :edit-detail-button="false"
              :delete-detail-button="false"
              :title="library.bookName"
              :categories="library.categories"
              :show-image="library.showImage"
              :image="library.image"
              :image-height="'[32rem]'"
              title-size="md"/>
      </div>
      <div v-else class="flex-1">
        <h1 class="text-">{{ $t("there are no lessons").capitalize() }}</h1>
      </div>
    </div>
    <div v-else class="md:col-span-4 col-span-4 row-span-3 border rounded-lg shadow-lg p-8 bg-white flex flex-col">
      <div class="flex flex-row justify-between">
        <h1 class="md:text-2xl text-lg font-bold mb-8 rounded-br-lg md:w-60 w-40 rounded-tl-lg">
          {{ $t('class programs').capitalize() }}</h1>
        <RouterLink to="/agenda" class="underline">{{ $t('view all programs').capitalize() }}</RouterLink>
      </div>
      <div
          class="grid grid-flow-col auto-cols-max md:grid-cols-4 gap-4 overflow-x-auto whitespace-nowrap md:overflow-x-visible md:whitespace-normal flex-1"
          v-if="programs.length">
        <card v-for="program in programs" :key="program.title" title-size="lg"
              class="bg-white hover:border-bg-300 hover:cursor-pointer hover:shadow-lg hover:scale-105 px-4 border border-bg-100 h-52 md:h-44 overflow-hidden"
              :showImage="program.showImage" :title="program.title"
              :showIconTitle="true"
              icon-name="fi fi-rr-calendar-day"
              @click="openProgram($event, program)"
              :description="program.description">
          <hr class="mt-4 mb-2">
          <div class="text-sm font-extrabold">
            <icon-component icon="fi fi-rr-book-copy"/>
            {{ $t('amount of lessons').capitalize() }}: {{program.books.length}}
            <div>
            </div>
          </div>
        </card>
      </div>
      <div v-else class="flex-1">
        <h1 class="text-">{{ $t("there are no programs").capitalize() }}</h1>
      </div>
    </div>
    <div  v-if="useAuthStore.role && useAuthStore?.role?.name === 'demo_user'"
          class="relative md:col-span-1 col-span-4 md:row-span-3 row-span-3 border rounded-lg shadow-lg flex flex-col bg-center overflow-hidden" >
      <div class="absolute inset-0 bg-demo-thumbnail bg-cover bg-center"></div>
      <div class="absolute inset-0 bg-FLPrimary opacity-50"></div>

      <div class="relative z-10 p-8 text-white">
        <h2 class="text-2xl font-bold">{{ $t('350+ dynamic learning lessons') }}</h2>
        <p>
          <ul class="list-disc pl-4 pt-4">
            <li><span class="font-bold">{{ $t('customized').capitalize() }}</span> {{ $t('for your school') }}</li>
            <li>{{ $t('grade').capitalize() }} <span class="font-bold">{{ $t('1 up to 8') }}</span></li>
            <li>{{ $t('in line with').capitalize() }} <span class="font-bold">SLO</span></li>
          </ul>
        </p>
        <div v-if="useAuthStore.role && useAuthStore?.role?.name === 'demo_user'"
             class="rounded-tr-xl rounded-br-xl pt-8 flex justify-center">
          <button-component
              @click="useAuthStore?.user?.request_upgrade ? useAppStore.showUpgradeFormModal = false : useAppStore.showUpgradeFormModal = true"
              :always-show-text="true" class="hover:scale-105 md:text-lg !mb-0 text-xs !p-6 text-white"
              button-color="secondary" :button-text="useAuthStore?.user?.request_upgrade ? 'requested!' : 'get access'"
              :disabled="!!useAuthStore?.user?.request_upgrade"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Card from "@/components/CardComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import {appStore} from "@/views/app.store";

import {useRouter} from 'vue-router';
import {computed, onMounted} from 'vue';
import VuePlyr from 'vue-plyr';
import IconComponent from "@/components/forms/IconComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: 'DashboardComponent',
  components: {
    ButtonComponent,
    Card,
    IconComponent,
    VuePlyr
  },
  setup() {
    const useLibraryStore = libraryStore();
    const useAgendaStore = agendaStore();
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();

    const openBook = async (event, book) => {
      try {
        await useLibraryStore.incrementViews(book.uuid);
        router.push(`/library/book/${book.uuid}`);
      } catch (error) {
        console.error('Error opening book:', error);
      }
    };

    const deleteBook = async (uuid) => {
      try {
        await useLibraryStore.deleteBook(uuid);
      } catch (error) {
        console.error('Error deleting book:', error);
      }
    };

    const updateBook = async (uuid) => {
      try {
        await useLibraryStore.fetchBook(uuid);
      } catch (error) {
        console.error('Error updating book:', error);
      }
    };

    const libraries = computed(() => useLibraryStore.dashboard_books.map(book => ({
      uuid: book.uuid,
      bookName: book.name,
      categories: book.categories,
      description: book.description,
      showImage: true,
      image: book.image,
      is_demo: book.is_demo,
      views: book.views || 0,
    })));

    const fetchBooks = async () => {
      useLibraryStore.pagination.perPage = 4;
      await useLibraryStore.fetchAllBooks(1, 'dashboard');
    };

    const fetchPrograms = async (amount = 4) => {
      useAgendaStore.queryFilter = 'amount=' + amount
      await useAgendaStore.fetchAllPrograms();
    };

    const programs = computed(() => useAgendaStore.programs.map(program => ({
      uuid: program.uuid,
      title: program.name,
      description: program.description,
      books: program.books
    })));

    const openProgram = (event, program) => {
      useAgendaStore.program = program;
      router.push(`/agenda/${program.uuid}`);
    };

    onMounted(() => {
      fetchBooks();
      fetchPrograms();
    });

    return {
      libraries,
      openBook,
      deleteBook,
      useAuthStore,
      useAppStore,
      updateBook,
      programs,
      openProgram,
    };
  },

  methods: {
    isDemoUser() {
      return this.useAuthStore?.user?.roles?.[0]?.name === 'demo_user';
    },
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles?.[0]?.name === 'demo_user' && !isDemo;
    },
  }
};
</script>

<template>
  <div v-if="useAppStore.showConsent" class="cookie-consent fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-lg w-full text-center">
      <p class="mb-4">{{$t('we use cookies to ensure you get the best experience.').capitalize()}}</p>
      <div class="flex justify-center space-x-4">
        <button class="px-4 py-2 bg-FLregistration text-white rounded" @click="useAppStore.acceptCookies()">{{$t('accept').capitalize()}}</button>
        <button class="px-4 py-2 bg-gray-500 text-white rounded" @click="useAppStore.refuseCookies()">{{$t('refuse').capitalize()}}</button>
      </div>
    </div>
  </div>

  <div v-else-if="useAppStore.cookiesDenied" class="cookie-consent fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded shadow-lg max-w-lg w-full text-center">
      <p class="mb-4">{{$t('you have refused cookies').capitalize()}}</p>
      <button class="px-4 py-2 bg-FLregistration text-white rounded" @click="useAppStore.showConsentAgain">{{$t('change decision').capitalize()}}</button>
    </div>
  </div>
</template>


<script>
import {appStore} from "@/views/app.store";

export default {
  name: 'CookieConsent',
  setup() {
    let useAppStore = appStore();

    return {
      useAppStore
    };
  },
  data() {
    return {

    };
  },
  props: {
    showConsent: {
      type: Boolean,
      default: false,
    },
    cookiesDenied: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = this.getCookie('cookies_accepted');
      if (consent === null) {
        this.useAppStore.showConsent = true;
      } else if (consent === 'false') {
        this.useAppStore.cookiesDenied = true;
      }
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

  },
};
</script>

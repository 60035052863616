<template>
  <div class="mb-4" :class="{'flex gap-4': inline}">
    <label v-if="label" :for="id" :class="{'block': !inline, 'mt-2': inline}" class="text-gray-700 mb-2">{{ $t(label).capitalize() }}</label>
    <select
        :id="id"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
        :class="{'w-full': !inline}"
        class="block px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option v-if="placeholder" disabled value="" >{{ $t(placeholder).capitalize() }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option.value">
        {{ $t(option.text).capitalize() }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'select-dropdown',
    },
  },
};
</script>

<style scoped>
select:focus {
  outline: none;
}
</style>

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.capitalizeLower = function() {
    return this.charAt(0).toLowerCase() + this.slice(1);
}

String.prototype.lowercase = function() {
    return this.toLowerCase();
}

String.prototype.isEmpty = function() {
    return (this.length === 0 || !this.trim());
};

String.prototype.isNotEmpty = function() {
    return (this.length > 0 || this.trim());
}

String.prototype.truncate = function(length) {
    if (this.length > length) {
        return  this.slice(0, length) + '...';
    }
    return this;
}

String.prototype.stripHtml = function() {
    return this.replace( /(<([^>]+)>)/ig, '');
}
String.prototype.humanize = function() {
    const date = new Date(this);

    if (isNaN(date)) {
        return this;
    }

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    };

    return date.toLocaleString("nl-NL", options);
};

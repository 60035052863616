<template>
  <!-- Make the container a flexbox with column layout -->
  <div class="flex flex-col border rounded-lg shadow-lg p-8 bg-white md:h-[calc(100vh-140px)]">
    <!-- Content that grows -->
    <div class="flex-grow">
      <div class="flex flex-wrap justify-between items-center">
        <div v-if="useLibraryStore.categories.length <= 0 && useAuthStore.permissions?.includes('books_create') && !useLibraryStore.isLoadingBooks"
             class="mb-4 p-2 w-full bg-orange-200 border-orange-400 border-2 rounded-lg">
          ⚠️{{ $t('to create a lesson you need to create a category').capitalize() }}.
          <RouterLink to="/categories"
                      class="bg-FLregistration px-1 border-2 border-FLregistration rounded text-white hover:bg-white hover:text-FLregistration">
            {{ $t('create').capitalize() }}
          </RouterLink>
        </div>
        <div class="flex gap-8">
        <h1 class="text-2xl font-bold">{{ $t('overview lessons').capitalize() }}</h1>
        </div>

        <div class="flex gap-8">
          <SelectComponent
              :options="perPageOptions"
              v-model="selectedPerPage"
              :inline="true"
              @update:modelValue="updatePerPage"
              label="lessons per page"
              inputColor="primary"
          />
          <button-component :disabled="useLibraryStore.categories.length <= 0 || useLibraryStore.isLoadingBooks" buttonText="create lesson"
                            buttonColor="secondary" prefix-icon="fi fi-br-book-plus"
                            v-if="useAuthStore.permissions?.includes('books_create')" @click="openCreateModal()"
                            class="ml-auto bg-FLregistration text-white py-2 px-4 rounded-full">
            <span class="hidden md:inline">{{ $t('create lesson').capitalize() }}</span>
            <i class="fi fi-rr-layer-plus md:hidden"></i>
          </button-component>
        </div>
      </div>

      <!-- Main content area with the books or loading spinner -->
      <div v-if="!useLibraryStore.isLoadingBooks">
        <div
            class="grid md:grid-cols-4 sm:grid-cols-1 gap-4 p-4 overflow-y-auto md:max-h-[calc(100vh-370px)] max-h-[calc(100vh-370px)]"
            v-if="libraries.length > 0" @scroll="handleScroll">
          <card v-for="library in libraries" :key="library.uuid"
                :class="['h-full text-white rounded transform transition duration-200 hover:scale-105 hover:shadow cursor-pointer hover:bg-FLPrimary bg-FLSecondary']"
                @edit="updateBook(library.uuid)"
                @delete="confirmDeleteBook(library.uuid)"
                @show="openBook($event, library)"
                updatePermission="books_update"
                deletePermission="books_delete"
                showPermission="books_view"
                :allow-edits="true"
                :title="library.bookName"
                :is-locked="isLocked(library.is_demo)"
                :is-demo="!!library.is_demo"
                :updated-at="library.updated_at"
                :categories="library.categories"
                :image="library.image"
                :show-image="library.showImage"
                :views="library.views"
                title-size="lg"/>
        </div>
        <div v-else class="text-center pt-8">
          <icon-component icon="fi fi-br-empty-set" class="text-6xl text-FLregistration"/>
          <h1 class="text-xl">{{ $t('there are no lessons').capitalize() }}</h1>
        </div>
      </div>
      <div v-else
           class="grid lg:grid-cols-4 rounded md:grid-cols-3 sm:grid-cols-1 gap-4 p-4 overflow-y-auto md:max-h-[calc(100vh-370px)] max-h-[calc(100vh-370px)]">
        <div v-for="i in 4" :key="i">
          <SkeletonLoader :style="{ height: 'calc(40vh - 9rem)', width: '100%', borderRadius: '0.5rem'}"/>
        </div>
      </div>
    </div>
    <!-- Pagination at the bottom of the container -->
    <div v-if="useLibraryStore.pagination.totalPages > 1" class="flex justify-center items-center space-x-2">
      <!-- Previous Page Button -->
      <button
          @click="changePage(useLibraryStore.pagination.currentPage - 1)"
          :disabled="useLibraryStore.pagination.currentPage === 1"
          class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg disabled:opacity-50"
      >
        {{ $t('previous') }}
      </button>

      <!-- Page Numbers -->
      <button
          v-for="page in pages"
          :key="page"
          @click="changePage(page)"
          :class="['px-4 py-2 rounded-lg', {
            'bg-FLregistration text-white': page === useLibraryStore.pagination.currentPage,
            'bg-gray-200 hover:text-white hover:bg-FLregistrationlight': page !== useLibraryStore.pagination.currentPage
          }]"
      >
        {{ page }}
      </button>

      <!-- Next Page Button -->
      <button
          @click="changePage(useLibraryStore.pagination.currentPage + 1)"
          :disabled="useLibraryStore.pagination.currentPage === useLibraryStore.pagination.totalPages"
          class="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg disabled:opacity-50"
      >
        {{ $t('next') }}
      </button>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import Card from "@/components/CardComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";
import IconComponent from "@/components/forms/IconComponent.vue";
import SelectComponent from "@/components/forms/SelectComponent.vue";

export default {
  name: 'OverviewBookComponent',
  components: {SelectComponent, IconComponent, ButtonComponent, Card},

  setup() {
    const useLibraryStore = libraryStore();
    const useAuthStore = authStore();
    const sweetAlertBook = ref(null);

    const perPageOptions = [
      { value: 12, text: '12' },
      { value: 24, text: '24' },
      { value: 36, text: '36' },
      { value: 48, text: '48' },
      { value: 60, text: '60' },
    ];

    const selectedPerPage = ref(useLibraryStore.pagination.perPage);


    const confirmDeleteBook = (uuid) => {
      showDeleteConfirmation(() => deleteBook(uuid));
    };

    const deleteBook = async (uuid) => {
      useLibraryStore.lastFetched = null;

      try {
        useLibraryStore.loading = true;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await useLibraryStore.deleteBook(uuid);
      } catch (error) {
        console.error('Error deleting book:', error);
      } finally {
        useLibraryStore.loading = false;
      }
    };

    const updatePerPage = async () => {
      useLibraryStore.pagination.perPage = selectedPerPage.value;
      useLibraryStore.pagination.currentPage = 1;
      useLibraryStore.lastFetched = null;
      await useLibraryStore.fetchAllBooks(1);
    };


    return {
      useLibraryStore,
      useAuthStore,
      sweetAlertBook,
      confirmDeleteBook,
      perPageOptions,
      selectedPerPage,
      updatePerPage,
    };
  },

  props: {
    libraries: {
      required: true,
      type: Array,
    },
  },

  computed: {
    pages() {
      const totalPages = this.useLibraryStore.pagination.totalPages;
      const currentPage = this.useLibraryStore.pagination.currentPage;
      const pagesToShow = 5;
      const half = Math.floor(pagesToShow / 2);

      let start = currentPage - half;
      let end = currentPage + half;

      if (start < 1) {
        start = 1;
        end = pagesToShow;
      }
      if (end > totalPages) {
        end = totalPages;
        start = totalPages - pagesToShow + 1;
        if (start < 1) start = 1;
      }

      return Array.from({ length: end - start + 1 }, (v, k) => start + k);
    },
  },

  methods: {
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles[0]?.name === 'demo_user' && !isDemo;
    },
    async openBook($e, book) {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.selectedBook = book;
        this.navigateTo($e, '/library/book/' + book.uuid);
      } catch (error) {
        console.error('Error opening book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    async changePage(page) {
      if (page !== this.useLibraryStore.pagination.currentPage) {
        this.useLibraryStore.lastFetched = null;
        this.useLibraryStore.pagination.currentPage = page;
        await this.useLibraryStore.fetchAllBooks(page, false);
      }
    },

    async openCreateModal() {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.resetForm();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = false;
      } catch (error) {
        console.error('Error opening create modal:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    async updateBook(uuid) {
      try {
        this.useLibraryStore.loading = true;
        await this.useLibraryStore.fetchBook(uuid, false);
        await this.useLibraryStore.setFormBook();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = true;
      } catch (error) {
        console.error('Error updating book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },
  },
};
</script>

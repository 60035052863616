import {defineStore} from "pinia";
import analyticsService from "@/views/analytics/services/analytics.service";
import {showErrorAlert} from "@/helpers/sweetAlert";

export const analyticsStore = defineStore('analyticsStore', {
    state: () => ({
        bugs: []
    }),
    actions: {

        async getAllBugReports(){
            try {
                const response = await analyticsService.getAllBugReports();
                this.bugs = response.data.data;
            } catch (error) {
                showErrorAlert('something went wrong', error.response.data.message.lowercase());
            }
        }
    }
});
